* {
  background-color: #f2ede6;
}


@font-face {
  font-family: "Satoshi";
  src: url("assets/fonts/Satoshi-Regular.otf");
}

.App {
  text-align: center;
}

body { 
  padding: 0;
  margin: 0;
}

