.swiper {
    .swiper-pagination {
        background-color: rgba(255, 255, 255, 0.5);
        color: #000;
    }
    width: 50rem;
    margin-left: 4rem;
    height: 35rem;   
    border-radius: 6px;
    outline: 3px solid black;

    @media screen and (min-width: 1440px) and (max-width: 2559px) {
            width: 70rem;
            height: auto;
            margin-left: 17rem;
    }
    
}

.embedDiv {
    margin-left: 4rem;
    width: 50rem;
    embed {
        width: 100%;
        height: 40rem;

        @media screen and (min-width: 1440px) and (max-width: 2559px) {
            margin-left: 23rem;
            height: 60rem;
    }
    }
}



